import React from "react";
import WaiverBanner from "../images/Heros/Waiver-Hero.jpg";
import Waiver from "../documents/waiver.pdf";
import { Layout, Seo } from "../components";

export default function WaiverPage() {
	const jumboTexts = [
		<p>Please print, complete, and bring a copy of the waiver to the field</p>,
		<p>Feel free to call us at (509) 919-4569 with any questions</p>,
	];

	return (
		<>
			<Layout
				heroImg={WaiverBanner}
				page=''
				headerText='Waiver'
				bodyText={jumboTexts}
				btnText='Download Waiver'
				btnRoute={Waiver}
				contentsDown=''
				defaultSport=''
				FAQHeaderImg=''
				FAQtexts={[]}
				altText=''>
				<Seo title='Waiver' description='NiteHawk waiver' />
			</Layout>
		</>
	);
}
